<template>
<div style="width:100%; margin:0 auto;">
  <div class="app-container">
    <div id="app-contain">
    <!-- <div class="filter-container" id="filter-container">
      <el-input v-model="listQuery.name" placeholder="名称" style="width: 200px;" class="filter-item" clearable />
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div> -->
    <el-table
      v-loading="listLoading"
      :data="tablelist"
      :height="tabheight"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
      <el-table-column label="序号" type="index" align="center" width="50x" />
      <el-table-column label="任务名称" header-align="center" align="left" prop="srName" min-width="150" />
      <el-table-column label="审批结果" header-align="center" align="left"  min-width="150" >
         <template slot-scope="scope">
          <span>{{ getDicName(scope.row.srProjectState,'YW_TASK_SCHOOL_STATE') }}</span>
          </template>
        </el-table-column>  
      <el-table-column label="审批人" header-align="center" align="left" prop="srCreateUser" min-width="150" />
      <el-table-column label="审批时间" header-align="center" align="right" prop="srCreateTime" width="200" show-overflow-tooltip />
      <el-table-column label="审批意见" header-align="center" align="left" prop="srApprovalComments" min-width="150" />

    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
    </div>
  </div>
</div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'
import { getLogPage } from '@/api/sysReportLog'


export default {
  components: { Pagination},
  mixins: [tableHeight2],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterText:null,
      list: null,
      tablelist:null,
      total: 0,
      listLoading: true,
      treeLoading:false,
      //任务页面
      asideAssignment:true,
      //表单页面
      asideTable:false,
      //1
      dialogVisibleBI:false,
      //2
      dialogVisibleLMP:false,
      //3
      dialogVisibleCU:false,
      //4
      dialogVisibleTI:false,
      //5
      dialogVisibleTE:false,
      //6
      dialogVisibleSEA:false,
      //7
      dialogVisibleLA:false,
      //8
      dialogVisibleLS:false,
      //9
      dialogVisibleEI:false,
   
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tabheight:0,
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      rules: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-15
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-15
        })()
      }
    })
  },
  created() {
    this.loadList()
    this.getDictList('YW_TASK_SCHOOL_STATE')
    //this.getTableTreeList();
    // this.getDictList('DOP_YW_DEPLOY_TYPE')
    // this.getDictList('DOP_YW_API_DEPLOY_STATE')
  },
  methods: {
    // getDictList(code) {
    //   getDictListByCode(code).then(response => {
    //     if (code === 'DOP_YW_DEPLOY_TYPE') {
    //       this.apiTypes = response.data
    //     } else if (code === 'DOP_YW_API_DEPLOY_STATE') {
    //       this.apiDeployStates = response.data
    //     }
    //   })
    // },
    // getDicName(code, flag) {
    //   var dict = []
    //   if (flag === 'DOP_YW_DEPLOY_TYPE') {
    //     dict = this.apiTypes
    //   } else if (flag === 'DOP_YW_API_DEPLOY_STATE') {
    //     dict = this.apiDeployStates
    //   }
    //   for (var i in dict) {
    //     if (dict[i].code === code) {
    //       retu rn dict[i].name
    //     }
    //   }
    // },
    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
  
    // //节点过滤
  
    // handleFilter() {
    //   this.listQuery.page = 1
    //   this.loadList()
    // },
     getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiTypes = response.data
        } else if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiTypes
      } else if (flag === 'YW_FLOW_STATE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    loadList() {
      this.listLoading = true
      this.listQuery.stsId=this.proid
      getLogPage(this.listQuery).then(response => {
        this.listLoading = false
        this.tablelist = response.data.records
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
      //任务列表
   
      // this.tablelist=[]
      // this.listLoading = false
      // this.total = parseInt(1)
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    handleEdit(row) {
      if(row.type===1){
        this.dialogVisibleBI=true
        this.$nextTick(() => {
         this.$refs.basicInformation
        })
      }
      else if(row.type===2){
        this.dialogVisibleLMP=true
        this.$nextTick(() => {
         this.$refs.laboratorymanagementpersonnel
        })
      }
      else if(row.type===3){
        this.dialogVisibleCU=true
        this.$nextTick(() => {
         this.$refs.classroomusage
        })
      }
      else if(row.type===4){
        this.dialogVisibleTI=true
        this.$nextTick(() => {
         this.$refs.teachinginstrument
        })
      }
      else if(row.type===5){
        this.dialogVisibleTE=true
        this.$nextTick(() => {
         this.$refs.teachingequipment
        })
      }
      else if(row.type===6){
        this.dialogVisibleSEA=true
        this.$nextTick(() => {
         this.$refs.scienceexperimentactual
        })
      }
      else if(row.type===7){
        this.dialogVisibleLA=true
        this.$nextTick(() => {
         this.$refs.libraryadministrators
        })
      }
      else if(row.type===8){
        this.dialogVisibleLS=true
        this.$nextTick(() => {
         this.$refs.librarysituation
        })
      }
      else if(row.type===9){
        this.dialogVisibleEI=true
        this.$nextTick(() => {
         this.$refs.educationalinvestment
        })
      }

          
    },
    // handleSubmitTest(row) {
    //   if (row.state !== '1' && row.state !== '5') {
    //     this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //   } else {
    //     MessageBox.confirm('确定提交测试吗', '确定', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       cancelButtonClass: 'qx',
    //       type: 'warning'
    //     }).then(() => {
    //       const loading = this.$loading({
    //         lock: true,
    //         text: 'Loading',
    //         spinner: 'el-icon-loading',
    //         background: 'rgba(0, 0, 0, 0.7)'
    //       })

    //       submitTest(row.id).then(response => {
    //         loading.close()
    //         this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //       }).catch(function() {
    //         loading.close()
    //       })
    //     })
    //   }
    // },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"22年教育装备填报",level:1,children:[
          {name:"表1 学校基本情况统计表",level:2,type:1,children:[]},
          {name:"表2 实验室管理人员情况统计表",level:2,type:2,children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",level:2,type:3,children:[]},
          {name:"表4 教学仪器情况统计表",level:2,type:4,children:[]},
          {name:"表5 教学设备情况统计表",level:2,type:5,children:[]},
          {name:"表6 理科实验开出情况统计表",level:2,type:6,children:[]},
          {name:"表7 图书管理人员情况统计表",level:2,type:7,children:[]},
          {name:"表8 图书馆（室）情况统计表",level:2,type:8,children:[]},
          {name:"表9 当年教育技术装备投入情况统计表",level:2,type:9,children:[]}
         
        ]
      }]
      this.treeLoading=false;
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    },
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
