<template>
  <div class="app-container">
    <div id="app-contain">
    <div class="filter-container" id="filter-container">
     <el-date-picker  v-model="strStYear"  type="year" class="filter-item"  value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"  placeholder="选择任务年度"> 
              </el-date-picker>
      <el-input v-model="listQuery.deptName"  placeholder="单位名称" style="width: 200px;" class="filter-item" clearable />
       <el-select v-model="listQuery.schoolType" placeholder="学校类型" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in schoolTypes"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
   <el-select v-model="listQuery.state" placeholder="任务状态" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in apiTypes"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
        查询
      </el-button>
     
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      :height="tabheight"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
       <el-table-column label="序号" type="index" :index="typeIndex" align="center" width="50x" />
       <el-table-column label="填报单位" header-align="center" align="left" prop="departmentName" min-width="250" />
        <el-table-column label="学校类型" header-align="center" align="left" width="140">
          <template slot-scope="scope">
          <span>{{ getDicName(scope.row.schoolType,'TXPE_OF_CAMOUS') }}</span>
          </template>
        </el-table-column>    
      <el-table-column label="任务名称" header-align="center" align="left" prop="name" min-width="250" />
      <el-table-column label="年度" header-align="center" align="left" prop="stYear" width="60" />
       <el-table-column label="任务状态" header-align="center" align="left" width="140">
          <template slot-scope="scope">
            <div :style="{color:scope.row.state == '3' ? 'green': (scope.row.state == '6' ? 'red':'brown')}">
          <span>{{ getDicName(scope.row.state,'YW_TASK_SCHOOL_STATE') }}</span>
            </div>
          </template>
        </el-table-column> 
          
      
    
      <el-table-column label="提交时间" header-align="center" align="right" prop="modifyTime" width="200" show-overflow-tooltip />
      <el-table-column label="提交人" header-align="center" align="left" prop="modifyUser" width="100" />

      <el-table-column
        fixed="right"
        label="操作"
        width="300"
        header-align="center"
        align="left"
      >
        <template slot-scope="scope">
          <span>
            <el-button v-if="scope.row.state!=6" type="primary" size="small" @click="handleSubmitTest(scope.row)">查看</el-button>
            <el-button v-if="scope.row.state==3" type="primary" size="small" @click="submit(scope.row)">驳回</el-button>

          </span>
        </template>
      </el-table-column>

    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
       <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleTask" width="90%" append-to-body :title="'任务详情'">
        <task-index v-if="dialogVisibleTask" ref="taskindex" :proid="code" :visible.sync="dialogVisibleTask" />
      </el-dialog>
       <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleExaLog" width="70%" append-to-body :title="'审批日志'">
        <examine-log v-if="dialogVisibleExaLog" ref="exaLog" :proid="code" :visible.sync="dialogVisibleExaLog" />
      </el-dialog>
      <el-dialog :close-on-click-modal="false"
        :title="'驳回'"
        width="45%"
        :visible.sync="bhView"
        :modal-append-to-body="false"
        @close="closeViewUser"
        
      >
        <el-form :label-position="'left'" label-width="100px" :model="form">
          
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item >
              <div slot="label">驳回意见<font color="red">*</font></div>
            <el-input type="textarea" maxlength="100" show-word-limit  rows="5" v-model="bhQuery.logText"  />
              </el-form-item>
            </el-col>
          </el-row>
          
        </el-form>

        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="bhSubmit">驳 回</el-button>
          <el-button type="danger" @click="bhView = false">取 消</el-button>
        </div>
      </el-dialog>
      </div>
  </div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'
import TaskIndex from '../pending/dataFilling/index.vue'
import ExamineLog from '../approved/ExamineLog.vue'
import { districtSpPage } from '@/api/taskSchool'
import{addLog} from '@/api/sysReportLog'
// import {getLogByDevelopment} from "@/api/deployLog"

export default {
  components: { Pagination,TaskIndex,ExamineLog },
  mixins: [tableHeight2],
  data() {
    return {
      filterText:null,
      list: null,
      total: 0,
      listLoading: true,
      treeLoading:false,
      dialogVisibleLog:false,
      dialogVisibleTask:false,
      dialogVisibleExaLog:false,
      strStYear:'',
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      bhView:false,
      schoolTypes:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tabheight:0,
      bhQuery:{
        logText:''
      },
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      rules: {
        itemId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-15
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-15
        })()
      }
    })
  },
  created() {
    
    this.loadList()
   
    this.getDictList('YW_TASK_SCHOOL_STATE')
    
    this.getDictList('TXPE_OF_CAMOUS')
  },
  methods: {
     typeIndex(index){
      let curPage=this.listQuery.page
      let curPagesize=this.listQuery.limit
      
      curPage=curPage >= 1 ? curPage : 1
      return index+1+(curPage-1)*curPagesize
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiTypes = response.data
        } else if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        }else if (code === 'TXPE_OF_CAMOUS') {
          this.schoolTypes = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiTypes
      } else if (flag === 'YW_FLOW_STATE') {
        dict = this.apiDeployStates
      }else if (flag === 'TXPE_OF_CAMOUS') {
        dict = this.schoolTypes
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
   
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    
    loadList() {
      if(this.strStYear==''){
        var time=new Date()
        var strTime =(time.getFullYear()-1)+"-"+(time.getMonth()+1)+"-"+(time.getDate())+" "+"00:00:00"
        this.strStYear=strTime
      }
      this.listQuery.strStYear=this.strStYear
      this.listLoading = true
      districtSpPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records
        
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })

    
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    handleEdit(row) {
      this.code=row.id
     this.dialogVisibleExaLog=true
      this.$nextTick(() => {
         this.$refs.taskindex
        })
    },
     handleSubmitTest(row) {
      this.code=row.id
      this.dialogVisibleTask=true
        this.$nextTick(() => {
         this.$refs.taskindex
        })
    },
    submit(row){
      this.bhView=true
     
      this.bhQuery.stsId=row.id
      this.bhQuery.state=4
      this.bhQuery.logText=null
      
    },
    bhSubmit(){
      this.bhView=true
      debugger
      if(this.bhQuery.logText==null||this.bhQuery.logText==''){
         Message({
            message: '驳回意见不能为空',
            type: 'error',
            duration: 5 * 1000
          })
          return
      }
      
      addLog(this.bhQuery).then(response=>{
         Message({
            message: '驳回成功',
            type: 'success',
            duration: 5 * 1000
          })
          
      this.loadList()
      this.bhView=false
      })
      
    },
   
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"教育装备填报",children:[
          {name:"表1 学校基本情况统计表",children:[]},
          {name:"表2 实验室管理人员情况统计表",children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",children:[]},
          {name:"表4 教学仪器情况统计表",children:[]},
          {name:"表5 教学设备情况统计表",children:[]},
          {name:"表6 理科实验开出情况统计表",children:[]},
          {name:"表7 图书管理人员情况统计表",children:[]}
        ]
      }]
      this.treeLoading=false;
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    },
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
//<style>中的样式
.red {
  color: red !important;
}
.green {
  color: #409eff !important;
}
.brown {
  color: #E3A869	 !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
